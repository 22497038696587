<script>
// import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from 'axios';
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import store from "@/state/store";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Setting",
    meta: [{
      name: "description",
      content: appConfig.description
    }],
  },
  data() {
    return {
      title: "Setting",
      user: [],
      loginUser: store.getters["login/name"],
      loginUname: store.getters["login/userName"],
      loginId: store.getters["login/id"],
      loginPass: "",
      showPass: false,
      showConfirmPass: false,
      confirmPass: "",
      pass: "",
      errorMessage: '',
      clinicConfig: [],
      config: {
        "startTime": '',
        "endTime": '',
        "interval": '',
        "address": '',
        "day": ''
      }
    };
  },
  components: {
    Layout,
  },
  methods: {
    showAlert() {
      Swal.fire("Details Updated Successfully!","success");
    },
    async getData() {
      const response = await axios.get("https://api.doctosoft.com/index.php/api/admin/user/" + this.loginId);
      this.user = response.data;
      this.loginPass = this.user.password;
      this.confirmPass = this.loginPass;
      store.commit('login/setId', this.user.id)
      store.commit('login/setUserName', this.user.userName)
      store.commit('login/setName', this.user.name)
    },
    async updateData() {
      if (this.loginPass === this.confirmPass) {
        this.pass = this.loginPass
      }
      else {
        console.log("Password not matched");
        this.errorMessage = "Password not matched";
        return;
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("name", this.loginUser);
      urlencoded.append("userName", this.loginUname);
      urlencoded.append("password", this.pass);
      urlencoded.append("role", this.user.role);
      await axios.put(
        " https://api.doctosoft.com/index.php/api/admin/user/" + this.loginId,
        urlencoded
      );

      this.getData();
    },
    async getClinic() {
      const response = await axios.get("https://api.doctosoft.com/index.php/api/app/config/");
      this.clinicConfig = response.data;
      this.config = this.clinicConfig.config;
    },
    async updateClinicDetails() {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("config", JSON.stringify(this.config));
      await axios.put(
        "https://api.doctosoft.com/index.php/api/app/config/" + 1,
        urlencoded
      );
    },
    toggleShowPassword() {
      this.showPass = !this.showPass;
    },
    toggleConfirmPassword() {
      this.showConfirmPass = !this.showConfirmPass;
    },
  },
  created() {
    this.getData();
    this.getClinic();
  },
  computed: {
    passType() {
      return this.showPass ? "text" : "password";
    },
    confirmpassType() {
      return this.showConfirmPass ? "text" : "password";
    },
    loginUsers() {
      return this.loginUser
    },
    loginUnames() {
      return this.loginUname
    },
    loginPassword() {
      return this.loginPass
    },
  },
};
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img src="@/assets/images/profile-bg.jpg" class="profile-wid-img" alt="" />
        <div class="overlay-content">
          <div class="text-end p-3">
            <div class="p-0 ms-auto rounded-circle profile-photo-edit">
              <input id="profile-foreground-img-file-input" type="file" class="profile-foreground-img-file-input" />
              <label for="profile-foreground-img-file-input" class="profile-photo-edit btn btn-light">
                <i class="ri-image-edit-line align-bottom me-1"></i> Change
                Cover
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-row>
      <b-col xxl="3">
        <b-card no-body class="mt-n5">
          <b-card-body class="p-4">
            <div class="text-center">
              <div class="
                    profile-user
                    position-relative
                    d-inline-block
                    mx-auto
                    mb-4
                  ">
                <div class="avatar-lg">
                  <div class="avatar-title rounded-circle bg-danger userprofile">
                    {{ loginUser.charAt(0) }}
                  </div>
                  <input id="profile-img-file-input" type="file" class="profile-img-file-input" />
                  <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                    <span class="avatar-title rounded-circle bg-light text-body">
                      <i class="ri-camera-fill"></i>
                    </span>
                  </label>
                </div>
              </div>
              <h5 class="fs-16 mb-1">{{ loginUser }}</h5>
              <p class="text-muted mb-0">User</p>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xxl="9">
        <b-card no-body class="mt-xxl-n5">
          <b-card-header>
            <ul class="
                      nav nav-tabs-custom
                      rounded
                      card-header-tabs
                      border-bottom-0
                    " role="tablist">
              <li class="nav-item">
                <b-link class="nav-link active" data-bs-toggle="tab" href="#generalDetails" role="tab">
                  <i class="fas fa-home"></i>
                  General Details
                </b-link>
              </li>
              <li class="nav-item">
                <b-link class="nav-link" data-bs-toggle="tab" href="#clinicDetails" role="tab">
                  <i class="far fa-envelope"></i>
                  Clinic Details
                </b-link>
              </li>
              <!-- <li class="nav-item">
                      <b-link class="nav-link" data-bs-toggle="tab" href="#privacy" role="tab">
                        <i class="far fa-envelope"></i>
                        Privacy Policy
                      </b-link>
                    </li> -->
            </ul>
          </b-card-header>
          <b-card-body class="p-4">
            <div class="tab-content">
              <div class="tab-pane active" id="generalDetails" role="tabpanel">
                <form @submit.prevent="updateData()">
                  <b-row>
                    <b-col lg="6">
                      <div class="mb-3">
                        <label for="name" class="form-label">Name</label>
                        <input :type="loginUsers" class="form-control" id="name" placeholder="Enter your name"
                          v-model="loginUser" />
                      </div>
                    </b-col>
                    <b-col lg="6">
                      <div class="mb-3">
                        <label for="username" class="form-label">User Name</label>
                        <input :type="loginUnames" class="form-control" id="username" placeholder="Enter your username"
                          v-model="loginUname" />
                      </div>
                    </b-col>
                    <b-col lg="6">
                      <div class="mb-3">
                        <label for="password" class="form-label">Password</label>
                        <input v-bind:type="passType" class="form-control" id="password" placeholder="Enter your password"
                          v-model="loginPass" />
                        <b-button variant="link" class="pform-control" type="button" id="password-addon"
                          @click="toggleShowPassword">
                          <i class="ri-eye-fill align-middle"></i>
                        </b-button>
                      </div>
                    </b-col>
                    <b-col lg="6">
                      <div class="mb-3">
                        <label for="confirm-password" class="form-label">Confirm Password</label>
                        <input v-bind:type="confirmpassType" class="form-control" id="confirm-password"
                          placeholder="Confirm password" v-model="confirmPass" />
                        <b-button variant="link" class="pform-control" type="button" id="password-addon"
                          @click="toggleConfirmPassword">
                          <i class="ri-eye-fill align-middle"></i>
                        </b-button>
                      </div>
                      <div>{{ errorMessage }}</div>
                    </b-col>


                    <b-col lg="12">
                      <div class="hstack gap-2 justify-content-end">
                        <b-button type="submit" variant="primary" @click="showAlert()">
                          Update
                        </b-button>
                        <b-button type="button" variant="soft-success" @click="this.$router.push('/admin')">
                          Cancel
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </form>
              </div>

              <!-------------------------Clinic Details---------------------------------------------->

              <div class="tab-pane" id="clinicDetails" role="tabpanel">
                <form @submit.prevent="updateClinicDetails()">
                  <div id="newlink">
                    <div id="1">
                      <b-row>
                        <b-col lg="6">
                          <div class="mb-3">
                            <label for="starttime" class="form-label">Start Time</label>
                            <input type="time" class="form-control" id="starttime" placeholder="Enter Start Time"
                              v-model="config.startTime" />
                          </div>
                        </b-col>
                        <b-col lg="6">
                          <div class="mb-3">
                            <label for="endtime" class="form-label">End Time</label>
                            <input type="time" class="form-control" id="endtime" placeholder="Enter End Time"
                              v-model="config.endTime" />
                          </div>
                        </b-col>
                        <b-col lg="6">
                          <div class="mb-3">
                            <label for="interval" class="form-label">Appointment Interval (in minutes)</label>
                            <input type="text" class="form-control" id="interval" placeholder="Enter Appointment Interval"
                              v-model="config.interval" />
                          </div>
                        </b-col>
                        <b-col lg="6">
                          <div class="mb-3">
                            <label for="address" class="form-label">Clinic Address</label>
                            <input type="text" class="form-control" id="address" placeholder="Enter Address"
                              v-model="config.address" />
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <b-col lg="6">
                    <div class="mb-3">
                      <label for="days" class="form-label">working Days</label>
                      <input type="text" class="form-control" id="days" placeholder="Enter No of working days"
                        v-model="config.day" />
                    </div>
                  </b-col>
                  <b-col lg="12">
                    <div class="hstack gap-2 justify-content-end">
                      <b-button type="submit" variant="primary" @click="showAlert()">
                        Update
                      </b-button>
                      <b-button type="button" variant="soft-success" @click="this.$router.push('/admin')">
                        Cancel
                      </b-button>
                    </div>
                  </b-col>
                </form>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
<style>
.mb-3 {
  position: relative;
}

.mb-3 button {
  position: absolute;
  top: 72%;
  transform: translateY(-50%);
  left: 450px;
}</style>